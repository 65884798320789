/* eslint-disable react/jsx-max-depth */
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import type { Locales } from "./libs/i18n/localeTypes";
import { Toaster } from "./components/shadcnui/ui/toaster";
import { ChordProgressionSearchBarProvider } from "./components/common/SiteHeader/useContext";
import {
  type ValidatedEnvVars,
  extractServerSideEnv,
} from "./libs/wrappers/extractServerSideEnv";
import { GithubLikeProgress } from "./components/common/GitHubLikeProgress";
import { LocaleProvider } from "./libs/i18n/context/LocaleProvider";
import { DictionaryProvider } from "./libs/i18n/context/DictionaryProvider";
import { defaultThemes } from "./libs/theme";
import { getCookieSessionHandlers } from "./sessions";
import { usePrereleaseConfirm } from "./libs/prerelease/usePrereleaseConfirm";
import { AppFooter } from "./components/common/AppFooter";
import { getLocale } from "~/libs/i18n/getLocale";
import globalCss from "~/tailwind.css?url";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: globalCss },
];

export type RootLoaderData = {
  locale: Locales;
  processEnv: ValidatedEnvVars["processEnv"];
  themeId: string;
  appStatus: ValidatedEnvVars["appStatus"];
  loginedUserId: string | undefined;
  turnStileSiteKey: string;
};

export async function loader({
  request,
  context,
}: LoaderFunctionArgs): Promise<RootLoaderData> {
  const appEnv = extractServerSideEnv(context.cloudflare.env);
  const sessionStorage = getCookieSessionHandlers(appEnv.sessionSecret);
  const session = await sessionStorage.getSession(
    request.headers.get("Cookie"),
  );

  // TODO: personalLocaleの設定処理をどっかで実装

  // TODO: それぞれログインユーザーならDBから取得してもいいかも？
  const themeId = session.get("themeId") ?? "appLight";
  const locale = getLocale({
    url: new URL(request.url),
    userSettingLanguage: session.get("personalLocale"),
  });
  const loginedUserId = session.get("loginedUserId");

  return {
    locale,
    processEnv: appEnv.processEnv,
    themeId,
    appStatus: appEnv.appStatus,
    loginedUserId,
    turnStileSiteKey: appEnv.turnStileSiteKey,
  };
}

export function Layout({
  children,
}: {
  readonly children: React.ReactNode;
}): JSX.Element {
  const loaderData = useLoaderData<typeof loader>();
  const isDevelopment = loaderData.processEnv === "development";
  const selectedThemeStyle = (defaultThemes[loaderData.themeId]?.cssVariables ??
    {}) as React.CSSProperties;

  usePrereleaseConfirm({
    locale: loaderData.locale,
    isPrerelease: loaderData.appStatus === "prerelease",
  });

  return (
    <html
      // NOTE: tailwindのデフォルトフォントサイズがでかいので調整する
      lang={loaderData.locale}
      // NOTE: Chromeで開発時に https://github.com/facebook/react/issues/11538 のエラーがうるさいので開発時だけワークアラウンド
      {...(isDevelopment ? { notranslate: "true" } : {})}
      style={selectedThemeStyle}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex flex-col">
        <LocaleProvider locale={loaderData.locale}>
          <DictionaryProvider>
            <ChordProgressionSearchBarProvider>
              {/* FIXME: これがあるとChromeで開発時に https://github.com/facebook/react/issues/11538 のエラーがうるさい。notranslateだけじゃないのか…。一旦残しておく */}
              {!isDevelopment && <GithubLikeProgress />}
              <div className="flex-1">{children}</div>
              <AppFooter />
            </ChordProgressionSearchBarProvider>
          </DictionaryProvider>
        </LocaleProvider>
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App(): JSX.Element {
  return <Outlet />;
}

export default withSentry(App);

// TODO: エラーページを整える
export function ErrorBoundary(): JSX.Element {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <>
      <h1>Error!</h1>
      {/* @ts-expect-error スルーする */}
      <p>{error?.message ?? "Unknown error"}</p>
    </>
  );
}
