export type CopyWriteProps = {
  readonly appName: string;
  readonly fromYear: number;
  readonly className?: string;
};

export function CopyWrite({
  appName,
  fromYear,
  className,
}: CopyWriteProps): JSX.Element {
  return (
    <div className={className}>
      © {fromYear} {appName}. All Rights Reserved.
    </div>
  );
}
