import { useEffect } from "react";
import type { Locales } from "../i18n/localeTypes";

let isConfirmed = false;

export function usePrereleaseConfirm({
  locale,
  isPrerelease,
}: {
  locale: Locales;
  isPrerelease: boolean;
}): void {
  useEffect(() => {
    if (isPrerelease && !isConfirmed) {
      // eslint-disable-next-line no-alert
      window.confirm(
        locale === "ja"
          ? "このアプリケーションはまだ開発中です。リリースまではモックを閲覧ください。"
          : "This application is still under development. Please view the mock until release.",
      );
      isConfirmed = true;
    }
  }, [isPrerelease, locale]);
}
