import { Link } from "@remix-run/react";

export type ListWithTitleProps = {
  readonly title: React.ReactNode;
  readonly list: {
    title: React.ReactNode;
    href: string;
  }[];
};

export function ListWithTitle({
  title,
  list,
}: ListWithTitleProps): JSX.Element {
  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-sm font-bold">{title}</h3>
      <ul className="flex flex-col gap-3 text-sm">
        {list.map((item) => (
          <li key={item.href}>
            <Link to={item.href} className="appLink">
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
