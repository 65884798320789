import { clsx } from "clsx";
import { useNavigation } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import { match, P } from "ts-pattern";

// 参考: https://dev.to/seasonedcc/creating-a-github-like-progress-bar-for-your-remix-app-153l
export function GithubLikeProgress(): JSX.Element {
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    Promise.allSettled(
      ref.current.getAnimations().map(async ({ finished }) => finished),
    ).then(() => {
      if (!active) {
        setAnimationComplete(true);
      }
    });
  }, [active]);

  return (
    <div
      role="progressbar"
      aria-hidden={!active}
      aria-valuetext={active ? "Loading" : undefined}
      aria-label="Loading"
      className="fixed inset-x-0 top-0 z-50 h-1 animate-pulse"
    >
      <div
        ref={ref}
        className={clsx(
          "h-full bg-gradient-to-r from-blue-500 to-cyan-500 transition-all duration-500 ease-in-out",
          match([navigation.state, animationComplete])
            .with(["idle", true], () => "w-0 opacity-0")
            .with(["submitting", P._], () => "w-4/12")
            .with(["loading", P._], () => "w-10/12")
            .otherwise(() => "w-full"),
        )}
      />
    </div>
  );
}
